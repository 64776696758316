import React from 'react';

import Box from '@mui/material/Box';

// import backgroundImage from '../../images/Bendable_poster_in_library.jpg';

const BendableHeroPortraitBG = (props) => {
  const {
    imageUrl = 'https://bendable.s3.us-west-1.amazonaws.com/mg_libraries_hero1.jpg',        // default hero image
    height = { xs: '450px', lg: '800px' },
  } = props;

  return (
    <Box
      sx={{
        width: '100%',
        height: height,
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default BendableHeroPortraitBG;